
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue"
import ResultSet from "@/models/ResultSet"
import OrganizationService from "@/services/OrganizationService"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import DataTable from "primevue/datatable"
import AuthService from "@/services/AuthService"
import LinkLabel from "../navigation/LinkLabel.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import CompaniesService from "@/services/CompaniesService"

export default {
  components: { LinkLabel, PrimaryButton },
  setup() {
    let part = 0
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const store = useStore()
    const router = useRouter()
    const organizationService = OrganizationService.getInstance()
    const companyService = new CompaniesService()
    const companies = reactive([])
    const selectedCompany = ref()
    const collectionComponent = ref()
    const items = ref([])
    const loading = ref(true)
    //const sort = ref("")
    const filter = ref("")
    const scrollComponent = ref()
    const windowWidth = ref(window.innerWidth)
    const timeout = ref(null)
    const scrollable = computed(() => windowWidth.value > 960)

    //const showSortOptions = computed(() => windowWidth.value < 960)

    const service = new AuthService()
    const loggingIn = ref(false)

    const VSOL_FRONTEND_BASE_URL = process.env.VUE_APP_VSOL_FRONTEND

    loadPart(0, filter.value)

    companyService.getCompanies().then((result: any) => {
      if (result.data && result.data.length) {
        result.data
          .sort((a: any, b: any) => {
            const codeA = a.code.toLowerCase()
            const codeB = b.code.toLowerCase()
            if (codeA < codeB) return -1
            if (codeA > codeB) return 1
            return 0
          })
          .forEach((company: any) => {
            companies.push(company)
          })
      }
    })

    function onOpenVSOL6(id: any) {
      loggingIn.value = true

      service.logInVsol(id).then((response) => {
        if (response.status == 200) {
          let url =
            VSOL_FRONTEND_BASE_URL +
            "login/support?" +
            new URLSearchParams(response.data.tokens[0]).toString()
          // let routeData = VSOL6_FRONTEND + router.resolve({path: "/login", query: }).href;
          window.open(url, "_blank")
        } else {
          console.error("Login failed", response)
        }

        loggingIn.value = false
      })
    }

    onMounted(() => {
      loading.value = false
      scrollComponent.value = document.querySelector(".p-datatable-wrapper")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    function search() {
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        reload()
      }, 300)
    }

    /*watch(
      () => filter.value,
      () => {
        reload()
      }
    )

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )*/

    function reload() {
      loadingPart.value = true
      part = 0
      //selectedItems.value = null
      loadedAllData.value = false
      items.value = []
      loadPart(part, filter.value)
    }

    function checkVisible() {
      windowWidth.value = window.innerWidth
      if (loadedAllData.value) return
      if (loadingPart.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart(part, filter.value)
      }
    }

    function loadPart(part: Number, filter: string) {
      loadingPart.value = true
      organizationService
        .getOrganizations(part, "", filter, selectedCompany.value?.id, 20)
        .then((result: ResultSet<any>) => {
          onPartLoaded(result)
        })
    }

    function onPartLoaded(result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome

      if (result.rows.length == 0) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        items.value.push(item)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    /*const sortColumn = (event: any) => {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    const sortOptions = ref([
      { label: "Code ↓", value: "code,asc" },
      { label: "Code ↑", value: "code,desc" },
      { label: "Name ↓", value: "name,asc" },
      { label: "Name ↑", value: "name,desc" },
    ])*/

    function onNew() {
      router.push({ name: "newOrganization" })
    }

    function onMigrate() {
      router.push({ name: "organizationMigration" })
    }

    return {
      //sortOptions,
      collectionComponent,
      loadPart,
      onNew,
      onMigrate,
      items,
      loading,
      //sortColumn,
      loggingIn,
      onOpenVSOL6,
      filter,
      //sort,
      //showSortOptions,
      search,
      scrollable,
      companies,
      selectedCompany,
      reload,
    }
  },
}
